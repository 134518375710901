import ReactDOM from "react-dom/client";

import { ThemeProvider } from "@mui/material/styles";

import App from "./App";
import "./index.css";
import "@fontsource/poppins";

import { AuthProvider } from "react-auth-kit";
// import refreshApi from "./components/queries/RefreshApi";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { CssBaseline } from "@mui/material";
import theme from "./theme";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    // refresh={refreshApi}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </ThemeProvider>
);
