import useStore from "../../store";
import { Stack, Chip } from "@mui/material";

const UserInfo = () => {
  const user = useStore((state) => state.user);

  return (
    <Stack spacing={1} direction="row" sx={{ my: 1 }}>
      <Chip size="small" label={user.name} />
      <Chip variant="outlined" size="small" label={user.email} />
    </Stack>
  );
};

export default UserInfo;
