import { useIsAuthenticated } from "react-auth-kit";
import { Container, Box } from "@mui/material";

import GetCurrentUser from "./components/common/GetCurrentUser";
import GetCurrentPartner from "./components/common/GetCurrentPartner";
import Tabs from "./components/common/Tabs";
import Login from "./components/login/Login";
import Content from "./components/common/Content";
import Logout from "./components/login/Logout";

const App = () => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated()) {

    return (
      <Container sx={{ py: 4 }} maxWidth={false}>
        <GetCurrentUser>
          <GetCurrentPartner>
            <Tabs />
            <Box>
              <Content />
            </Box>
          </GetCurrentPartner>
        </GetCurrentUser>
        <Logout />
      </Container>
    );
  }

  return <Login />;
}

export default App;
