import { useEffect } from "react";
import useStore from "../../store";

import { useAuthHeader, useSignOut } from "react-auth-kit";
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";

import Loading from "./Loading";

import { getRequest } from "../../functions";

const GetCurrentUser = ({ children }) => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();

  const setUser = useStore((state) => state.setUser);
  const setAppTab = useStore((state) => state.setAppTab);
  const setSelectedPartnerNip = useStore(
    (state) => state.setSelectedPartnerNip
  );

  const { isFetching, isError, data } = useQuery({
    queryKey: ["me"],
    queryFn: () => getRequest("users/me?context=edit", authHeader()),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (isFetching || !data) {
      return;
    }

    if (isError) {
      signOut();
    }

    const isAdmin = data.roles.includes("administrator");

    setUser({
      isAdmin,
      name: data.name,
      email: data.email
    });

    if (!isAdmin) {
      setAppTab("shops");
      setSelectedPartnerNip(data.meta.nip);
    }
  }, [
    isFetching,
    data,
    isError,
    signOut,
    setUser,
    setAppTab,
    setSelectedPartnerNip
  ]);

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  return <>{children}</>;
};

export default GetCurrentUser;
