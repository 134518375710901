import useStore from "../../store";

import Partners from "../partners/Partners";
import Shops from "../shops/Shops";
import History from "../history/History";
import Newspapers from "../newspapers/Newspapers";

const Content = () => {
  const appTab = useStore((state) => state.appTab);

  if (appTab === "partners") {
    return <Partners />;
  }

  if (appTab === "shops" || appTab === "order" || appTab === "email") {
    return <Shops />;
  }

  if (appTab === "history") {
    return <History />;
  }

  if (appTab === "newspapers") {
    return <Newspapers />;
  }

  return;
};

export default Content;
