import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const MonthTable = ({ month }) => {
  const theme = useTheme();

  const title = `Miesiąc ${month.month}`;
  return (
    <TableContainer
      sx={{ mb: 4, border: `5px solid ${theme.palette.bg.main}` }}
    >
      <Table aria-label={title}>
        <TableHead>
          <TableRow>
            <TableCell align="right">ID</TableCell>
            <TableCell>Dostawca</TableCell>
            <TableCell align="right">Obrót</TableCell>
            <TableCell align="right">Procent</TableCell>
            <TableCell align="right">Wartość</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {month.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="right">{row.id_dostawcy}</TableCell>
              <TableCell>{row.dostawca}</TableCell>
              <TableCell align="right">
                {parseFloat(row.obrot).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {parseFloat(row.procent).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {parseFloat(row.wartosc).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MonthTable;
