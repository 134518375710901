import useStore from "../../store";

import { Chip } from "@mui/material";

const YearCard = ({ year }) => {
  const selectedPartnerYear = useStore((state) => state.selectedPartnerYear);
  const setSelectedPartnerYear = useStore(
    (state) => state.setSelectedPartnerYear
  );

  return (
    <Chip
      variant={year === selectedPartnerYear ? "filled" : "outlined"}
      label={year}
      onClick={() => setSelectedPartnerYear(year)}
      color="primary"
    />
  );
};

export default YearCard;
