import useStore from "../../store";

import { Stack } from "@mui/material";

import Title from "../common/Title";
import PartnerInfo from "../common/PartnerInfo";
import Years from "./Years";
import Quaters from "./Quaters";
import QuaterBilling from "./QuaterBilling";

const History = () => {
  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);
  const selectedPartnerYear = useStore((state) => state.selectedPartnerYear);
  const selectedPartnerQuater = useStore(
    (state) => state.selectedPartnerQuater
  );

  return (
    <>
      <Title text="Rozliczenia">
        <PartnerInfo />
      </Title>
      <Stack spacing={1} direction="row" sx={{ mb: 10 }}>
        {selectedPartnerNip > 0 && <Years />}
        {selectedPartnerYear > 0 && <Quaters />}
      </Stack>
      {selectedPartnerQuater > 0 && <QuaterBilling />}
    </>
  );
};

export default History;
