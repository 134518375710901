import { useSignOut } from "react-auth-kit";

import useStore from "../../store";

import { Button } from "@mui/material";

const Logout = () => {
  const signOut = useSignOut();

  const reset = useStore((state) => state.reset);

  const handleClick = () => {
    reset();
    signOut();
  };

  return (
    <Button variant="outlined" sx={{ mt: 2 }} onClick={() => handleClick()}>
      Wyloguj
    </Button>
  );
};

export default Logout;
