import useStore from "../../store";
import Image from "mui-image";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";

const NewspaperCard = ({ id, image, title, link }) => {
  const setNewspaperModalData = useStore(
    (state) => state.setNewspaperModalData
  );
  const setNewspaperModalIsOpen = useStore(
    (state) => state.setNewspaperModalIsOpen
  );

  const handleCardClick = () => {
    setNewspaperModalData({
      id,
      title,
      link,
    });
    setNewspaperModalIsOpen(true);
  };

  return (
    <Card variant="outlined">
      <CardActionArea onClick={() => handleCardClick()}>
        <CardContent>
          <Image src={image} />
          <Typography variant="body1" noWrap sx={{ mt: 1 }}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NewspaperCard;
