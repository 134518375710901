import { useAuthHeader } from "react-auth-kit";
import { useQuery } from "@tanstack/react-query";

import { Box, Grid } from "@mui/material";

import { postRequest } from "../../functions";

import Loading from "../common/Loading";
import Title from "../common/Title";
import NewspaperCard from "./NewspaperCard";
import NewspaperModal from "./NewspaperModal";

const Newspapers = () => {
  const authHeader = useAuthHeader();
  const { isFetching, isError, data } = useQuery({
    queryKey: ["get_gazetki"],
    queryFn: () => postRequest("get_gazetki", authHeader()),
    refetchOnWindowFocus: false
  });

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  return (
    <>
      <Title text="Gazetki" />
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {data.map((newspaper) => (
          <Grid key={newspaper.id} item xs={12} sm={6} md={4} lg={3}>
            <NewspaperCard
              id={newspaper.id}
              image={newspaper.image}
              title={newspaper.title}
              link={newspaper.link}
            />
          </Grid>
        ))}
      </Grid>
      <NewspaperModal />
    </>
  );
};

export default Newspapers;
