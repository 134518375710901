import useStore from "../../../store";

import { useMutation } from "@tanstack/react-query";
import { useAuthHeader } from "react-auth-kit";

import {
  Modal,
  Card,
  Box,
  Alert,
  TextField,
  Typography,
  Button
} from "@mui/material";

import { postRequest } from "../../../functions";

import Loading from "../../common/Loading";

const OrderModal = ({ orderSum }) => {
  const authHeader = useAuthHeader();

  const orderModalIsOpen = useStore((state) => state.orderModalIsOpen);
  const setOrderModalIsOpen = useStore((state) => state.setOrderModalIsOpen);
  const selectedProducts = useStore((state) => state.selectedProducts);
  const resetSelectedProducts = useStore(
    (state) => state.resetSelectedProducts
  );
  const partner = useStore((state) => state.partner);
  const shop = useStore((state) => state.shop);

  const partnerMessageText = useStore((state) => state.partnerMessageText);
  const setPartnerMessageText = useStore(
    (state) => state.setPartnerMessageText
  );

  const send_order = useMutation({
    mutationFn: () => {
      return postRequest("send_order", authHeader(), {
        text: partnerMessageText,
        partner,
        shop,
        items: selectedProducts
      });
    },
    onSuccess: () => {
      setPartnerMessageText("");
      resetSelectedProducts("");
    }
  });

  const handleCloseModal = () => {
    send_order.reset();
    setOrderModalIsOpen(false);
  };

  const handleTextChange = (e) => {
    setPartnerMessageText(e.target.value);
  };

  const handleSendClick = () => {
    send_order.mutate();
  };

  return (
    <Modal open={orderModalIsOpen} onClose={handleCloseModal}>
      <Card sx={{ width: 400, p: 3, m: "120px auto 0" }}>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography>Nowe zamówienie</Typography>
          <Button onClick={handleCloseModal}>Zamknij</Button>
        </Box>
        {send_order.isPending ? (
          <Loading />
        ) : send_order.isSuccess && send_order.data ? (
          <Alert>Zamówienie zostało wysłane</Alert>
        ) : (
          <Box>
            <Box>
              <TextField
                fullWidth
                multiline={true}
                label="Dodatkowa notatka do zamówienia"
                name="message"
                minRows={4}
                onChange={handleTextChange}
                value={partnerMessageText}
              />
            </Box>
            <Box pt={2}>
              <Typography align="center">Suma zamówienia</Typography>
              <Typography align="center" sx={{ fontWeight: 600 }}>
                {orderSum.toFixed(2)} PLN
              </Typography>
            </Box>
            {((send_order.isSuccess && !send_order.data) ||
              send_order.isError) && (
              <Box sx={{ py: 2 }}>
                <Alert severity="warning">
                  Pomyłka przy wysłaniu zamówienia
                </Alert>
              </Box>
            )}
            <Box pt={2} align="center">
              <Button
                variant="contained"
                disabled={orderSum <= 0}
                onClick={handleSendClick}
              >
                Wyślij zamówienie
              </Button>
            </Box>
          </Box>
        )}
      </Card>
    </Modal>
  );
};

export default OrderModal;
