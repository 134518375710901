import useStore from "../../store";

import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Stack,
  Chip
} from "@mui/material";

const PartnerCard = ({ partner }) => {
  const setAppTab = useStore((state) => state.setAppTab);
  const setSelectedPartnerNip = useStore(
    (state) => state.setSelectedPartnerNip
  );

  return (
    <Card variant="outlined">
      <CardActionArea
        onClick={() => {
          setAppTab("shops");
          setSelectedPartnerNip(partner.nip);
        }}
      >
        <CardContent>
          <Typography variant="body1" noWrap sx={{ mb: 2 }}>
            {partner.wlasciciel}
          </Typography>
          <Stack
            direction="row"
            spacing={0}
            sx={{ mb: 1, flexWrap: "wrap", gap: 1 }}
          >
            <Chip size="small" label={partner.id} />
            <Chip variant="outlined" size="small" label={partner.email} />
          </Stack>
          <Stack direction="row" spacing={0} sx={{ flexWrap: "wrap", gap: 1 }}>
            <Chip
              variant="outlined"
              size="small"
              label={`NIP: ${partner.nip}`}
            />
            <Chip
              variant="outlined"
              size="small"
              label={`TEL: ${partner.telefon}`}
            />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PartnerCard;
