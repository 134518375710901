import useStore from "../../store";

import { Chip } from "@mui/material";

const QuaterCard = ({ quater }) => {
  const selectedPartnerQuater = useStore(
    (state) => state.selectedPartnerQuater
  );
  const setSelectedPartnerQuater = useStore(
    (state) => state.setSelectedPartnerQuater
  );

  return (
    <Chip
      variant={quater === selectedPartnerQuater ? "filled" : "outlined"}
      label={quater}
      onClick={() => setSelectedPartnerQuater(quater)}
      color="primary"
    />
  );
};

export default QuaterCard;
