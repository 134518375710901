import useStore from "../../store";

import { useAuthHeader } from "react-auth-kit";

import { Box, Grid } from "@mui/material";

import { useQuery } from "@tanstack/react-query";

import { postRequest } from "../../functions";

import Loading from "../common/Loading";
import MonthTable from "./MonthTable";
import MonthStat from "./MonthStat";

const QuaterBilling = () => {
  const authHeader = useAuthHeader();
  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);
  const selectedPartnerYear = useStore((state) => state.selectedPartnerYear);
  const selectedPartnerQuater = useStore(
    (state) => state.selectedPartnerQuater
  );

  const { isFetching, isError, data } = useQuery({
    queryKey: [
      "get_nip_billing_quater",
      selectedPartnerNip,
      selectedPartnerYear,
      selectedPartnerQuater
    ],
    queryFn: () =>
      postRequest("get_nip_billing_quater", authHeader(), {
        nip: selectedPartnerNip,
        rok: selectedPartnerYear,
        quater: selectedPartnerQuater
      }),
    refetchOnWindowFocus: false
  });

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <MonthStat month={data[0]} />
        <MonthTable month={data[0]} />
        <MonthStat month={data[0]} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <MonthStat month={data[1]} />
        <MonthTable month={data[1]} />
        <MonthStat month={data[1]} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <MonthStat month={data[2]} />
        <MonthTable month={data[2]} />
        <MonthStat month={data[2]} />
      </Grid>
    </Grid>
  );
};

export default QuaterBilling;
