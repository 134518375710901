import useStore from "../../../store";

import { useAuthHeader } from "react-auth-kit";
import { useMutation } from "@tanstack/react-query";

import { Box, TextField, Alert, Button } from "@mui/material";

import { postRequest } from "../../../functions";

import Loading from "../../common/Loading";
import Title from "../../common/Title";
import PartnerInfo from "../../common/PartnerInfo";
import ShopInfo from "../ShopInfo";

const Message = () => {
  const authHeader = useAuthHeader();

  const partner = useStore((state) => state.partner);
  const shop = useStore((state) => state.shop);
  const partnerMessageText = useStore((state) => state.partnerMessageText);
  const setPartnerMessageText = useStore(
    (state) => state.setPartnerMessageText
  );

  const send_email = useMutation({
    mutationFn: () => {
      return postRequest("send_email", authHeader(), {
        text: partnerMessageText,
        partner,
        shop
      });
    },
    onSuccess: () => {
      setPartnerMessageText("");
    }
  });

  const handleTextChange = (e) => {
    setPartnerMessageText(e.target.value);
  };

  const handleSendClick = () => {
    send_email.mutate();
  };

  const handleResetClick = () => {
    send_email.reset();
  };

  return (
    <Box>
      <Title text="Wyślij wiadomość">
        <PartnerInfo />
      </Title>
      <Box sx={{ maxWidth: 700, my: 4 }}>
        <ShopInfo />
        {send_email.isPending ? (
          <Loading />
        ) : send_email.isSuccess && send_email.data ? (
          <Alert
            action={
              <Button color="inherit" size="small" onClick={handleResetClick}>
                Nowa wiadomość
              </Button>
            }
          >
            Wiadomość została wysłana
          </Alert>
        ) : (
          <Box>
            <Box>
              <TextField
                fullWidth
                multiline={true}
                label="Powiadomienie"
                name="message"
                minRows={4}
                onChange={handleTextChange}
                value={partnerMessageText}
              />
            </Box>
            {((send_email.isSuccess && !send_email.data) ||
              send_email.isError) && (
              <Box sx={{ py: 2 }}>
                <Alert severity="warning">
                  Pomyłka przy wysłaniu wiadomości
                </Alert>
              </Box>
            )}
            <Box sx={{ py: 2, textAlign: "right" }}>
              <Button variant="outlined" onClick={handleSendClick}>
                Wyslij
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Message;
