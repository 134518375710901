import { Skeleton } from "@mui/material";

const Loading = () => {
  return (
    <>
      <Skeleton variant="rounded" sx={{ mb: 3, height: 30 }} />
      <Skeleton variant="rounded" sx={{ mb: 3, height: 100 }} />
      <Skeleton variant="rounded" sx={{ mb: 3, height: 80 }} />
      <Skeleton variant="rounded" sx={{ mb: 3, height: 150 }} />
    </>
  );
};

export default Loading;
