import useStore from "../../store";
import { Grid, Box, Typography } from "@mui/material";

const MonthStat = ({ month }) => {
  const selectedPartnerYear = useStore((state) => state.selectedPartnerYear);

  return (
    <Grid container spacing={2} sx={{ mb: 3, textAlign: "center" }}>
      <Grid item xs={12} md={4}>
        <Box>
          <Typography>{`${month.month}.${selectedPartnerYear}`}</Typography>
          <Typography variant="caption">Miesiąc</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box>
          <Typography>{parseFloat(month.stat.obrot).toFixed(2)} PLN</Typography>
          <Typography variant="caption">Obrót</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box>
          <Typography>
            {parseFloat(month.stat.wartosc).toFixed(2)} PLN
          </Typography>
          <Typography variant="caption">Wartość</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MonthStat;
