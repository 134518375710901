import useStore from "../../store";

import { Modal, Card, Box, Typography, Button } from "@mui/material";

const NewspaperModal = () => {
  const newspaperModalIsOpen = useStore((state) => state.newspaperModalIsOpen);
  const setNewspaperModalIsOpen = useStore(
    (state) => state.setNewspaperModalIsOpen
  );
  const newspaperModalData = useStore((state) => state.newspaperModalData);

  const handleCloseModal = () => {
    setNewspaperModalIsOpen(false);
  };

  return (
    <Modal open={newspaperModalIsOpen} onClose={handleCloseModal}>
      <Card sx={{ width: "100%", maxWidth: 1000, p: 3, m: "20px auto 0" }}>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>{newspaperModalData.title}</Typography>
          <Button onClick={handleCloseModal}>Zamknij</Button>
        </Box>
        <iframe
          width="100%"
          height="800px"
          src={newspaperModalData.link}
          title={newspaperModalData.title}
          frameBorder="0"
        />
      </Card>
    </Modal>
  );
};

export default NewspaperModal;
