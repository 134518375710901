import useStore from "../../store";
import ShopsList from "./ShopsList";

import Order from "./order/Order";
import Message from "./message/Message";

const Shops = () => {
  const shopsTab = useStore((state) => state.shopsTab);

  if (shopsTab === 'order') {
    return <Order />;
  }

  if (shopsTab === 'message') {
    return <Message />;
  }

  return <ShopsList />;
};

export default Shops;
