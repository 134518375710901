import Image from "mui-image";
import { Box } from "@mui/material";
import logo from "../../assets/logo-eden-transparent.png";

const Logo = () => {
  return (
    <Box sx={{ width: 132, height: 80, flexShrink: 0 }}>
      <Image src={logo} />
    </Box>
  );
};

export default Logo;
