import axios from "axios";

const site = `${process.env.REACT_APP_SITE_URL}`;

export const postRequest = async (method, authHeader, params = {}) => {
  const response = await axios.post(`${site}/wp-json/eden/v1/${method}`, params, {
    headers: {
      Authorization: authHeader,
    },
  });
  return response.data;
};

export const getRequest = async (method, authHeader) => {
  const response = await axios.get(`${site}/wp-json/wp/v2/${method}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  return response.data;
};

