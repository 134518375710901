import useStore from "../../store";

import { useAuthHeader } from "react-auth-kit";

import { Chip } from "@mui/material";

import { useQuery } from "@tanstack/react-query";

import { postRequest } from "../../functions";

import QuaterCard from "./QuaterCard";

const Years = () => {
  const authHeader = useAuthHeader();
  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);
  const selectedPartnerYear = useStore((state) => state.selectedPartnerYear);

  const { isFetching, data } = useQuery({
    queryKey: [
      "get_nip_billing_quaters",
      selectedPartnerNip,
      selectedPartnerYear
    ],
    queryFn: () =>
      postRequest("get_nip_billing_quaters", authHeader(), {
        nip: selectedPartnerNip,
        rok: selectedPartnerYear
      }),
    refetchOnWindowFocus: false
  });

  if (isFetching) {
    return <Chip label="Ładowanie" />;
  }

  return (
    <>
      <Chip label={"Wybierz kwartał"} />
      {data.map((quater) => (
        <QuaterCard key={quater} quater={quater} />
      ))}
    </>
  );
};

export default Years;
