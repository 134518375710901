import { useEffect } from "react";
import useStore from "../../store";

import { useAuthHeader, useSignOut } from "react-auth-kit";
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";

import Loading from "./Loading";

import { postRequest } from "../../functions";

const GetCurrentPartner = ({ children }) => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();

  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);
  const setPartner = useStore((state) => state.setPartner);

  const { isFetching, isError, data } = useQuery({
    queryKey: ["get_user_by_nip", selectedPartnerNip],
    queryFn: () =>
      postRequest("get_user_by_nip", authHeader(), {
        nip: selectedPartnerNip
      }),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (isFetching || !data) {
      return;
    }

    if (isError) {
      signOut();
    }

    setPartner({
      id: data.id,
      wlasciciel: data.wlasciciel,
      email: data.email,
      nip: data.nip,
      telefon: data.telefon
    });
  }, [isFetching, data, isError, signOut, setPartner]);

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  return <>{children}</>;
};

export default GetCurrentPartner;
