import useStore from "../../store";

import { useAuthHeader } from "react-auth-kit";
import { useQuery } from "@tanstack/react-query";

import { Box, Grid } from "@mui/material";

import { postRequest } from "../../functions";

import Loading from "../common/Loading";
import Title from "../common/Title";
import PartnerInfo from "../common/PartnerInfo";
import ShopCard from "./ShopCard";

const ShopsList = () => {
  const authHeader = useAuthHeader();
  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);

  const { isFetching, isError, data } = useQuery({
    queryKey: ["get_nip_shops", selectedPartnerNip],
    queryFn: () =>
      postRequest("get_nip_shops", authHeader(), {
        nip: selectedPartnerNip
      }),
    refetchOnWindowFocus: false
  });

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  return (
    <>
      <Title text="Sklepy">
        <PartnerInfo />
      </Title>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {data.map((shop) => (
          <Grid key={shop.id} item xs={12} sm={6} md={4} lg={3}>
            <ShopCard shop={shop} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ShopsList;
