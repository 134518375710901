import useStore from "../../store";
import { useAuthHeader } from "react-auth-kit";
import { useQuery } from "@tanstack/react-query";

import { Box, Grid } from "@mui/material";

import { postRequest } from "../../functions";

import Loading from "../common/Loading";
import Title from "../common/Title";
import UserInfo from "./UserInfo";
import PartnerCard from "./PartnerCard";
import SearchPartner from "./SearchPartner";

const Partners = () => {
  const authHeader = useAuthHeader();
  const searchUser = useStore((state) => state.searchUser);

  const { isFetching, isError, data } = useQuery({
    queryKey: ["get_users"],
    queryFn: () => postRequest("get_users", authHeader()),
    refetchOnWindowFocus: false
  });

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  const filteredUsersList = searchUser.length
    ? data.filter(
        (item) =>
          item.wlasciciel.toUpperCase().includes(searchUser) ||
          item.nip.toUpperCase().includes(searchUser)
      )
    : [...data];

  return (
    <>
      <Title text="Uzytkownicy">
        <UserInfo />
      </Title>
      <SearchPartner />
      <Grid container spacing={3}>
        {filteredUsersList.map((partner) => (
          <Grid key={partner.id} item xs={12} sm={6} md={4} lg={3}>
            <PartnerCard partner={partner} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Partners;
