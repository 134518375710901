import { create } from "zustand";

const initialState = {

  user: {},
  partner: {},
  shop: {},

  searchUser: '',
  appTab: 'partners',
  shopsTab: 'shops',

  selectedPartnerNip: 0,
  selectedPartnerYear: 0,
  selectedPartnerQuater: 0,

  selectedProducts: [],

  partnerMessageText: '',

  orderModalIsOpen: false,

  newspaperModalIsOpen: false,
  newspaperModalData: {},
};

const useStore = create((set, get) => ({
  ...initialState,

  reset: () => {
    set({ ...initialState });
  },

  setUser: (upadatedValue) => {
    set({ user: { ...upadatedValue } });
  },

  setPartner: (upadatedValue) => {
    set({ partner: { ...upadatedValue } });
  },

  setShop: (upadatedValue) => {
    set({ shop: { ...upadatedValue } });
  },

  resetShop: () => {
    get().setShop(initialState.shop)
  },

  resetUser: () => {
    get().setUser(initialState.user)
  },

  setSearchUser: (upadatedValue) => {
    set({ searchUser: upadatedValue });
  },

  setAppTab: (upadatedValue) => {
    if (upadatedValue !== null) {
      set({ appTab: upadatedValue });
    }
    get().resetSelectedPartnerYear();
    get().resetShopsTab();
  },

  setShopsTab: (upadatedValue) => {
    set({ shopsTab: upadatedValue });
    get().resetPartnerMessageText();
  },

  resetShopsTab: () => {
    get().setShopsTab(initialState.shopsTab);
  },

  setSelectedPartnerNip: (upadatedValue) => {
    get().resetSelectedProducts();
    get().resetShop();
    set({ selectedPartnerNip: upadatedValue });
  },

  setSelectedPartnerYear: (upadatedValue) => {
    set({ selectedPartnerYear: upadatedValue });
    get().resetSelectedPartnerQuater();
  },

  resetSelectedPartnerYear: () => {
    get().setSelectedPartnerYear(initialState.selectedPartnerYear);
  },

  setSelectedPartnerQuater: (upadatedValue) => {
    set({ selectedPartnerQuater: upadatedValue });
  },

  resetSelectedPartnerQuater: () => {
    get().setSelectedPartnerQuater(initialState.selectedPartnerQuater);
  },

  setSelectedProducts: (upadatedValue) => {
    set({ selectedProducts: [...upadatedValue] });
  },

  resetSelectedProducts: () => {
    get().setSelectedProducts(initialState.selectedProducts);
  },

  setPartnerMessageText: (upadatedValue) => {
    set({ partnerMessageText: upadatedValue });
  },

  resetPartnerMessageText: () => {
    get().setPartnerMessageText(initialState.partnerMessageText);
  },

  setOrderModalIsOpen: (upadatedValue) => {
    set({ orderModalIsOpen: upadatedValue });
  },

  setNewspaperModalIsOpen: (upadatedValue) => {
    set({ newspaperModalIsOpen: upadatedValue });
  },

  setNewspaperModalData: (upadatedValue) => {
    set({ newspaperModalData: { ...upadatedValue } });
  },

}));

export default useStore;
