import useStore from "../../store";
import { Box, Stack, Chip } from "@mui/material";

const ShopInfo = () => {
  const shop = useStore((state) => state.shop);

  return (
    <Box sx={{ mb: 4 }}>
      <Stack spacing={1} direction="row" sx={{ mb: 1 }}>
        <Chip size="small" label="Sklep" />
        <Chip variant="outlined" size="small" label={shop.eden_id} />
        <Chip variant="outlined" size="small" label={shop.ulica} />
        <Chip variant="outlined" size="small" label={shop.kod_pocztowy} />
        <Chip variant="outlined" size="small" label={shop.miejscowosc} />
      </Stack>
      <Stack spacing={1} direction="row">
        <Chip size="small" label="PH" />
        <Chip variant="outlined" size="small" label={shop.ph} />
        <Chip variant="outlined" size="small" label={shop.mail_ph} />
      </Stack>
    </Box>
  );
};

export default ShopInfo;
