import useStore from "../../store";

import { useAuthHeader } from "react-auth-kit";

import { Chip } from "@mui/material";

import { useQuery } from "@tanstack/react-query";

import { postRequest } from "../../functions";

import YearCard from "./YearCard";

const Years = () => {
  const authHeader = useAuthHeader();
  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);

  const { isFetching, data } = useQuery({
    queryKey: ["get_nip_billing_years", selectedPartnerNip],
    queryFn: () =>
      postRequest("get_nip_billing_years", authHeader(), {
        nip: selectedPartnerNip
      }),
    refetchOnWindowFocus: false
  });

  if (isFetching) {
    return <Chip label="Ładowanie" />;
  }

  if (!data.length) {
    return;
  }

  return (
    <>
      <Chip label={"Wybierz rok"} />
      {data.map((year) => (
        <YearCard key={year} year={year} />
      ))}
    </>
  );
};

export default Years;
