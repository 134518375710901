import { useEffect, useState } from "react";
import useStore from "../../../store";

import { useAuthHeader } from "react-auth-kit";
import { useQuery } from "@tanstack/react-query";

import { Box, Fab } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import { postRequest } from "../../../functions";

import Loading from "../../common/Loading";
import Title from "../../common/Title";
import PartnerInfo from "../../common/PartnerInfo";
import ShopInfo from "../ShopInfo";
import OrderModal from "./OrderModal";

const roundToNearestHalf = (number) => {
  return Math.round(number * 2) / 2;
};

const Order = () => {
  const apiRef = useGridApiRef();
  const authHeader = useAuthHeader();

  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);
  const setOrderModalIsOpen = useStore((state) => state.setOrderModalIsOpen);
  const selectedProducts = useStore((state) => state.selectedProducts);
  const setSelectedProducts = useStore((state) => state.setSelectedProducts);

  const { isFetching, isError, data } = useQuery({
    queryKey: ["get_newspaper", selectedPartnerNip],
    queryFn: () =>
      postRequest("get_newspaper", authHeader(), {
        nip: selectedPartnerNip
      }),
    refetchOnWindowFocus: false
  });

  const [orderSum, setOrderSum] = useState(0);

  const columns = [
    { field: "id", headerName: "ID", type: "number", flex: 1 },
    { field: "title", headerName: "Tytuł", flex: 10 },
    { field: "dostawca", headerName: "Dostawca", flex: 5 },
    {
      field: "count",
      headerName: "Zamówienie (op.)",
      flex: 3,
      type: "number",
      editable: true,
      valueGetter: (params) =>
        selectedProducts.find((product) => product.id === params.row.id)
          ?.count ?? 0
    },
    {
      field: "total",
      headerName: "Łącznie PLN",
      flex: 3,
      type: "number",
      valueGetter: (params) =>
        (
          params.api.getCellValue(params.id, "count") *
          parseFloat(params.row.cena_opak.replace(",", "."))
        ).toFixed(2)
    },
    {
      field: "opak",
      headerName: "Ilość w op.",
      flex: 3,
      type: "number"
    },
    {
      field: "cena_opak",
      headerName: "Cena op.",
      type: "number",
      flex: 3
    },
    { field: "cena_szt", headerName: "Cena szt.", type: "number", flex: 3 },
    {
      field: "cena_brutto",
      headerName: "Cena brutto",
      type: "number",
      flex: 3
    },
    {
      field: "marza_procent",
      headerName: "Marża",
      flex: 3,
      headerAlign: "right",
      align: "right"
    },
    {
      field: "cena_gazetka",
      headerName: "Cena gazetka",
      flex: 3,
      type: "number"
    },
    {
      field: "vat",
      headerName: "VAT",
      flex: 3,
      headerAlign: "right",
      align: "right"
    }
  ];

  const handleCellEdit = (newRow) => {
    const filteredRows = selectedProducts.filter(
      (item) => item.id !== newRow.id
    );
    const count = roundToNearestHalf(newRow.count);
    setSelectedProducts(
      count > 0 ? [...filteredRows, { ...newRow, count }] : filteredRows
    );
    return newRow;
  };

  useEffect(() => {
    setOrderSum(
      selectedProducts.reduce(
        (acc, val) =>
          acc +
          parseFloat(val.count) * parseFloat(val.cena_opak.replace(",", ".")),
        0
      )
    );
  }, [selectedProducts]);

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Box sx={{ mb: 3 }}>Error</Box>;
  }

  return (
    <Box>
      <Title text="Złóż zamówienie">
        <PartnerInfo />
      </Title>
      <ShopInfo />
      <DataGrid
        apiRef={apiRef}
        autoHeight={true}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25
            }
          }
        }}
        pageSizeOptions={[25, 50, 75, 100]}
        processRowUpdate={(updatedRow) => handleCellEdit(updatedRow)}
        onProcessRowUpdateError={() => {
          console.log("error");
        }}
        getRowClassName={(params) => {
          return (
            apiRef.current.getCellValue(params.id, "count") > 0 &&
            "positive-qty-row"
          );
        }}
        rowSelection={false}
      />
      {selectedProducts.length > 0 && (
        <Fab
          color="primary"
          variant="extended"
          sx={{ position: "fixed", bottom: 40, right: 40 }}
          onClick={() => setOrderModalIsOpen(true)}
        >
          Złóż zamówienie: {orderSum.toFixed(2)} PLN
        </Fab>
      )}
      <OrderModal orderSum={orderSum} />
    </Box>
  );
};

export default Order;
