import { createTheme } from "@mui/material/styles";
// import { orange } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    bg: { main: "#eef9fc", contrastText: "#000" },
    primary: { main: "#FF8811", contrastText: "#fff" },
    // secondary: orange,
    // red: { main: "#f44336", contrastText: "#fff" },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: 40,
    },
  },
});

export default theme;
