import { useState } from "react";

import axios from "axios";
import { useSignIn } from "react-auth-kit";

import { TextField, Button, Box } from "@mui/material";

import Logo from "../common/Logo";

const Login = () => {
  const signIn = useSignIn();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_SITE_URL}/wp-json/api-bearer-auth/v1/login`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          signIn({
            token: res.data.access_token,
            expiresIn: res.data.expires_in,
            tokenType: "Bearer",
            authState: { state: res.data },
            // refreshToken: res.data.refreshToken,
            // refreshTokenExpireIn: res.data.expiresIn,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Box sx={{ maxWidth: 350, mt: 10, mx: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <Logo />
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            sx={{ mb: 2 }}
            name="username"
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            sx={{ mb: 2 }}
            name="password"
            label="Password"
            value={formData.password}
            onChange={handleChange}
            required
            fullWidth
          />
          <Box sx={{ textAlign: "right" }}>
            <Button type="submit" variant="contained" color="primary">
              Login
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Login;
