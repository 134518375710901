import useStore from "../../store";

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Stack,
  Chip
} from "@mui/material";

const ShopCard = ({ shop }) => {
  const setShop = useStore((state) => state.setShop);
  const setShopsTab = useStore((state) => state.setShopsTab);

  const handleMessageClick = (shop) => {
    setShop(shop);
    setShopsTab("message");
  };

  const handleOrderClick = (shop) => {
    setShop(shop);
    setShopsTab("order");
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body1" noWrap sx={{ mb: 2 }}>
          {shop.title}
        </Typography>
        <Stack
          direction="row"
          spacing={0}
          sx={{ mb: 1, flexWrap: "wrap", gap: 1 }}
        >
          <Chip size="small" label={shop.eden_id} />
          <Chip variant="outlined" size="small" label={shop.ulica} />
          <Chip variant="outlined" size="small" label={shop.kod_pocztowy} />
          <Chip variant="outlined" size="small" label={shop.miejscowosc} />
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{ mb: 1, flexWrap: "wrap", gap: 1 }}
        >
          <Chip size="small" label="PH" />
          <Chip variant="outlined" size="small" label={shop.ph} />
          <Chip variant="outlined" size="small" label={shop.mail_ph} />
        </Stack>
      </CardContent>
      <CardActions sx={{ pl: 2, pb: 2 }}>
        <Stack
          direction="row"
          spacing={0}
          sx={{ mb: 1, flexWrap: "wrap", gap: 1 }}
        >
          <Chip
            label="Zamówienie"
            variant="contained"
            color="primary"
            onClick={() => handleOrderClick(shop)}
          />
          <Chip
            label="Wiadomośc"
            variant="outlined"
            color="primary"
            onClick={() => handleMessageClick(shop)}
          />
        </Stack>
      </CardActions>
    </Card>
  );
};

export default ShopCard;
