import useStore from "../../store";
import { Stack, Chip } from "@mui/material";

const PartnerInfo = () => {
  const partner = useStore((state) => state.partner);

  return (
    <Stack spacing={1} direction="row" sx={{ my: 1 }}>
      <Chip size="small" label="Użytkownik" />
      <Chip variant="outlined" size="small" label={partner.id} />
      <Chip variant="outlined" size="small" label={partner.wlasciciel} />
      <Chip variant="outlined" size="small" label={partner.email} />
      <Chip variant="outlined" size="small" label={`NIP: ${partner.nip}`} />
      <Chip variant="outlined" size="small" label={`TEL: ${partner.telefon}`} />
    </Stack>
  );
};

export default PartnerInfo;
