import useStore from "../../store";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useMediaQuery, createTheme } from "@mui/material";

const Tabs = () => {
  const theme = createTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const orientation = isSmScreen ? "horizontal" : "vertical";

  const user = useStore((state) => state.user);
  const appTab = useStore((state) => state.appTab);
  const setAppTab = useStore((state) => state.setAppTab);

  const selectedPartnerNip = useStore((state) => state.selectedPartnerNip);

  const userListButton = user.isAdmin && (
    <ToggleButton value="partners">Użytkownicy</ToggleButton>
  );

  const handleChange = (e, newTab) => {
    setAppTab(newTab);
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={appTab}
        exclusive
        onChange={handleChange}
        aria-label="Menu główne"
        fullWidth={true}
        size="large"
        sx={{ mb: 5 }}
        orientation={orientation}
      >
        {userListButton}
        <ToggleButton disabled={!selectedPartnerNip} value="shops">
          Sklepy
        </ToggleButton>
        <ToggleButton disabled={!selectedPartnerNip} value="history">
          Rozliczenia
        </ToggleButton>
        <ToggleButton value="newspapers">Gazetki</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default Tabs;
