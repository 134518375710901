import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import Logo from "./Logo";

const Title = ({ children, text }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 4,
        px: 1,
        background: theme.palette.bg.main,
      }}
    >
      <Box>
        <Typography
          component="h1"
          variant="h1"
          sx={{ textTransform: "uppercase" }}
        >
          {text}
        </Typography>
        {children}
      </Box>
      <Logo />
    </Box>
  );
};

export default Title;
