import useStore from "../../store";
import { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";

const SearchPartner = () => {
  const [search, setSearch] = useState("");
  const setSearchUser = useStore((state) => state.setSearchUser);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchUser(search.toUpperCase());
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search, setSearchUser]);

  return (
    <Box sx={{ mb: 4 }}>
      <TextField
        value={search}
        label="Szukaj imię lub NIP"
        fullWidth
        onChange={handleSearchChange}
      />
    </Box>
  );
};

export default SearchPartner;
